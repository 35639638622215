import gql from 'graphql-tag';

export default gql`
mutation deleteFormItem($obj: deleteFormItemInput!) {
  deleteFormItem(object: $obj) {
    form_id
    folder_id
    project_id
    page_id
  }
}
`;
