<template>
  <div>
    <b-row class="mb-2">
      <b-col cols="12" offset-sm="1" sm="11" class="d-flex align-items-center">
        <h3 class="mb-0">{{ formItem.name }}</h3>
        <b-dropdown
            class="context-menu"
            toggle-class="dot-button"
            no-caret
            no-flip
            right
        >
          <template #button-content>
            <b-icon icon="three-dots-vertical" aria-hidden="true" scale="1"></b-icon>
          </template>
          <b-dropdown-item v-b-modal.modal-edit-form-item>
            {{ $t('dropdowns.edit') }}
          </b-dropdown-item>
          <b-dropdown-item v-b-modal.modal-delete-form-item>
            {{ $t('dropdowns.delete') }}
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
      <b-col cols="12" offset-sm="1" sm="11">
        <hr>
      </b-col>
      <modal-edit-form-item
          class="d-inline-block"
          :formItem="formItem"
          @editFormItem="editFormItem"
      ></modal-edit-form-item>
      <modal-delete-form-item
          class="d-inline-block"
          :formItem="formItem"
          @deleteFormItem="deleteFormItem"
      ></modal-delete-form-item>
    </b-row>
    <b-row class="mb-5">
      <b-col cols="12" offset-sm="1" sm="11">
        <p class="mb-0">{{ formItem.description }}</p>
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col cols="12" offset-sm="1" sm="11">
        <b-button
            class="green-button-transparent border-0 mr-3 ml-3 mb-3"
            @click="sendFormResponse"
        >
          <b-icon
              icon="plus-circle-fill"
              class="mr-2 top--3"
              aria-hidden="true"
              scale="1"
          ></b-icon>
          Send Form Response
        </b-button>
    </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col cols="12" offset-sm="1" sm="11">
        <div class="form-builder p-4 row">
          <b-col cols="6">
            <h4>Form Builder</h4>
            <p class="mb-0">Form Builder Description</p>
          </b-col>
          <b-col class="align-content-end" cols="6" >
            <div class="float-right d-flex">
              <b-button
                  class="green-button-transparent border-0 mr-3 ml-3 mb-3"
                  @click="runLoader"
              >
                <b-icon
                    icon="plus-circle-fill"
                    class="mr-2 top--3"
                    aria-hidden="true"
                    scale="1"
                ></b-icon>
                {{ (formDetailData.form_data ?
                  $t('buttons.editFormContent') : $t('buttons.createFormContent')) }}
              </b-button>
            </div>
          </b-col>
        </div>
      </b-col>
      <modal-edit-form-content
          v-if="openEditFormItemModal"
          class="d-inline-block"
          :formDetails="formDetailData"
          @editFormContent="editFormContent"
          @cancel="openEditFormItemModal = false"
      ></modal-edit-form-content>
    </b-row>
    <b-row class="mb-2">
      <b-col cols="12" offset-sm="1" sm="3" class="d-flex align-items-center">
        <h3 class="mb-0 text-uppercase">
          {{ $t('modals.formItemDescription.responses') }}
        </h3>
      </b-col>
      <b-col cols="12" sm="8" class="align-content-end">
        <div class="float-right d-flex">
          <b-input-group class="search-group d-flex align-items-center">
            <b-icon
                icon="search"
                aria-hidden="true"
                scale="1"
            ></b-icon>
            <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                :placeholder="$t('webmag.search')"
            ></b-form-input>
          </b-input-group>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col cols="12" offset-sm="1" sm="11">
        <b-table
            v-if="responses"
            @sort-changed="sortChanged"
            @context-changed="sortChanged"
            id="responsesTable"
            ref="responsesTable"
            :fields="responsesFields"
            :items="responses"
            :filter="filter"
            sort-icon-left
            show-empty
            borderless
            dark
            class="table-view responses-table"
        >
          <template #table-colgroup="scope">
            <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="{ width: field.width }"
            >
          </template>
          <template #cell(order)="data">
            <div @click="data.toggleDetails">
              {{ data.item.id }}
            </div>
          </template>
          <template #cell(name)="data">
            <div @click="data.toggleDetails">
              {{ data.item.name }}
            </div>
          </template>
          <template #cell(created)="data">
            <div @click="data.toggleDetails">
              {{ data.item.created_at.substring(0, 10) }}
            </div>
          </template>
          <template #cell(image)="data">
            <div class="chevron-parent">
              <div class="contacts-header-arrows d-flex" @click="data.toggleDetails">
                <b-icon
                    v-if="!data.item._showDetails"
                    icon="chevron-right"
                    class="top-4 position-relative"
                    style="color: #fff"
                    aria-hidden="true"
                    scale="1.2"
                ></b-icon>
                <b-icon
                    v-else
                    icon="chevron-down"
                    class="top-4 position-relative"
                    style="color: #fff"
                    aria-hidden="true"
                    scale="1.2"
                ></b-icon>
              </div>
            </div>
          </template>
          <template #row-details="data">
            <b-card>
              <b-row>
                <b-col>
                  <div :id="'surveyElement-' + data.item.id">
                    <form-results
                        :formItemId="data.item.id"
                        :formResults="data.item.response_data"
                        :formContent="formDetailData.form_data"
                    />
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col cols="12" offset-sm="1" sm="3" class="d-flex align-items-center">
        <h3 class="mb-0 text-uppercase">
          {{ $t('modals.formItemDescription.items') }}
        </h3>
      </b-col>
      <b-col cols="12" sm="8" class="align-content-end">
        <div class="float-right">
          <modal-add-new-report-item
              v-if="userId"
              class="d-inline-block"
              :user-id="userId"
              :items-from-d-b="items"
              @addItems="addItems"
          ></modal-add-new-report-item>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col cols="12" offset-sm="1" sm="11">
        <b-table
            :fields="itemFields"
            :items="items"
            show-empty
            borderless
            dark
            class="table-view items-table"
        >
          <template #table-colgroup="scope">
            <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="{ width: field.width }"
            >
          </template>
          <template #cell(image)="data">
            <div
                v-if="data.item.type === 'folder'"
                class="cell-folder cell-background-image d-flex
            justify-content-center align-items-center"
            >
              <b-icon
                  icon="folder"
                  aria-hidden="true"
                  scale="1"
              ></b-icon>
            </div>
            <div
                v-if="data.item.type === 'project'"
                class="cell-background-image d-flex justify-content-center align-items-center"
            >
              <b-icon
                  icon="files"
                  aria-hidden="true"
                  scale="1"
              ></b-icon>
            </div>
            <div
                v-if="data.item.type === 'page'"
                class="cell-background-image d-flex justify-content-center align-items-center"
            >
              <b-icon
                  icon="file"
                  aria-hidden="true"
                  scale="1"
              ></b-icon>
            </div>
          </template>
          <template #cell(name)="data">
            <span :id="`tooltip-name-${data.item.id}`">
            {{ data.item.name | sliceStingPos(23) }}
            </span>
            <b-tooltip
                v-if="data.item.name.length > 23"
                :target="`tooltip-name-${data.item.id}`"
            >
              {{ data.item.name }}
            </b-tooltip>
          </template>
          <template #cell(group)="data">
            <span :id="`tooltip-group-${data.item.id}`">
            {{ data.item.group_name | sliceStingPos(20) }}
            </span>
            <b-tooltip
                v-if="data.item.group_name.length > 20"
                :target="`tooltip-group-${data.item.id}`"
            >
              {{ data.item.group_name }}
            </b-tooltip>
          </template>
          <template #cell(dot)="data">
            <b-dropdown
                class="context-menu"
                toggle-class="dot-button"
                no-caret
                dropup
                no-flip
                right
            >
              <template #button-content>
                <b-icon icon="three-dots-vertical" aria-hidden="true" scale=".8"></b-icon>
              </template>
              <b-dropdown-item
                  v-b-modal="`remove-${data.item.type}-${data.item.id}-${formItemId}`"
              >
                {{ $t('modals.deleteItemFromForm.dropdown') }}
              </b-dropdown-item>
            </b-dropdown>
            <modal-remove-item-from-form
                class="d-inline-block"
                :item="data.item"
                :form-index="formItemId"
                @remove-item="removeItem"
            ></modal-remove-item-from-form>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row v-if="isSysAdmin" class="mb-5" >
      <b-col cols="12" offset-sm="1" sm="11" style="border: 1px solid red;">
        <div id="template-checkbox-section" class="pt-3 pb-4">
          <b-form-checkbox v-model="useAsTemplate" @change="updateTemplateFlag">
            {{ $t('settings.forms.useAsTemplate') }}
          </b-form-checkbox>
        </div>
        <div v-if="useAsTemplate" id="image-upload-section" class="pb-4">
          <image-upload
            :image-object="templateImageObject"
            @upload-image-object="updateTemplateImage"
            @file-removed="removeTemplateImage"
          />
          <b-form-text>{{ $t('settings.forms.templateImgDesc') }}</b-form-text>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import SetLoadingIndicatorInCache from '@/graphQlQueries/mutations/setLoadingIndicatorInCache';
import GetFormDetails from '@/graphQlQueries/queries/forms/getFormDetails';
import InsertFormItem from '@/graphQlQueries/mutations/forms/insertFormItem';
import DeleteFormItem from '@/graphQlQueries/mutations/forms/deleteFormItem';
import InsertFormRes from '@/graphQlQueries/mutations/forms/insertFormRes';
import UpdateForm from '@/graphQlQueries/mutations/forms/updateForm';

export default {
  name: 'FormItemListView',
  components: {
    ModalEditFormItem: () => import('@/components/modals/forms/ModalEditFormItem.vue'),
    ModalDeleteFormItem: () => import('@/components/modals/forms/ModalDeleteFormItem.vue'),
    ModalEditFormContent: () => import('@/components/modals/forms/ModalEditFormContent.vue'),
    ModalAddNewReportItem: () => import('@/components/modals/reports/ModalAddNewReportItem.vue'),
    ModalRemoveItemFromForm: () => import('@/components/modals/forms/ModalRemoveItemFromForm.vue'),
    FormResults: () => import('@/components/settings/forms/FormResults.vue'),
    ImageUpload: () => import('@/components/helper/ImageUpload.vue'),
  },
  props: [
    'formItem',
    'formItemId',
    'formIndex',
    'userId',
    'isSysAdmin',
  ],
  data() {
    return {
      formFields: [
        {
          key: 'image',
          label: '',
          sortable: false,
          width: '50px',
          tdClass: 'image_column',
        },
        {
          key: 'name',
          label: '',
          sortable: false,
          width: 'auto',
          tdClass: 'name_column',
        },
        {
          key: 'dot',
          label: '',
          sortable: false,
          width: '40px',
        },
      ],
      responsesFields: [
        {
          key: 'order',
          label: 'Order',
          sortable: false,
          width: '50px',
          tdClass: 'order_column',
        },
        {
          key: 'name',
          label: this.$t('teamManagement.table.name'),
          sortable: false,
          width: 'auto',
          tdClass: 'name_column',
        },
        {
          key: 'created',
          label: 'Created',
          sortable: true,
          width: '100px',
          tdClass: 'created_column',
        },
        {
          key: 'dot',
          label: '',
          sortable: false,
          width: '40px',
        },
        {
          key: 'image',
          label: '',
          sortable: false,
          width: '50px',
          tdClass: 'image_column',
        },
      ],
      itemFields: [
        {
          key: 'image',
          label: '',
          sortable: false,
          width: '50px',
          tdClass: 'image_column',
        },
        {
          key: 'name',
          label: this.$t('teamManagement.table.name'),
          sortable: true,
          width: '200px',
          tdClass: 'name_column',
        },
        {
          key: 'group',
          label: this.$t('teamManagement.table.group'),
          sortable: true,
          width: 'auto',
          tdClass: 'group_column',
        },
        {
          key: 'dot',
          label: '',
          sortable: false,
          width: '30px',
        },
      ],
      itemsLoaded: false,
      isCollapseOpen: false,
      formDetailData: {
        form_id: null,
        name: null,
        description: null,
        form_data: null,
      },
      responses: [],
      items: [],
      showAddFormModal: false,
      openEditFormItemModal: false,
      openDeleteFormItemModal: false,
      filter: null,
      number: 42,
      useAsTemplate: false,
      templateImageUrl: null,
    };
  },
  filters: {
    sliceStingPos(value, length) {
      return (value.length > length) ? `${value.slice(0, length)}...` : value;
    },
  },
  computed: {
    templateImageObject() {
      return {
        url: this.templateImageUrl,
        width: null,
        height: null,
        focalpoint: {
          x: 50,
          y: 50,
        },
      };
    },
  },
  apollo: {
    formDetailData: {
      variables() {
        return {
          formId: this.formItemId,
        };
      },
      query: GetFormDetails,
      update(data) {
        // build the responses
        const responseObjects = [];
        if (data.getFormDetails.form_responses?.length) {
          let responseId = 1;
          data.getFormDetails.form_responses.forEach((response) => {
            const element = {
              id: responseId,
              name: `RES${responseId}`,
              response_data: response.response_data,
              created_at: response.created_at,
              _showDetails: false,
            };
            responseId++;
            responseObjects.push(element);
          });
        }
        this.templateImageUrl = (data.getFormDetails.preview_image_url)
          ? data.getFormDetails.preview_image_url
          : null;
        this.useAsTemplate = data.getFormDetails.is_template;
        this.responses = responseObjects;

        // build the items
        const itemObjects = [];
        if (data.getFormDetails.forms_items?.length) {
          let itemId = 1;
          data.getFormDetails.forms_items.forEach((item) => {
            const element = {
              id: itemId,
              folder_id: item.folder_id,
              project_id: item.project_id,
              page_id: item.page_id,
              name: item.name === 'root_folder' ? item.group_name : item.name,
              group_name: item.group_name,
              slug_path: item.slug_path,
              type: item.type,
            };
            itemId++;
            itemObjects.push(element);
          });
        }
        // sort the items after group_name and then after path
        itemObjects.sort((a, b) => {
          const textA = a.group_name.toUpperCase();
          const textB = b.group_name.toUpperCase();
          const textC = a.name.toUpperCase();
          const textD = b.name.toUpperCase();
          // eslint-disable-next-line no-nested-ternary,max-len
          return (textA < textB) ? -1 : (textA > textB) ? 1 : (textC < textD) ? -1 : (textC > textD) ? 1 : 0;
        });
        this.itemsLoaded = true;
        this.items = itemObjects;

        // build the form details
        const formObject = {
          form_id: data.getFormDetails.form_id,
          name: data.getFormDetails.name,
          description: data.getFormDetails.description,
          form_data: data.getFormDetails.form_data,
        };
        return formObject;
      },
      skip() {
        return !this.formItemId;
      },
      fetchPolicy: 'network-only',
    },
  },
  methods: {
    sortChanged() {
      this.number++;
    },
    async editFormItem() {
      this.$emit('changeInFormList', this.formItemId);
    },
    async deleteFormItem() {
      this.$emit('changeInFormList', -1);
    },
    createForm() {
      console.log('creating form...');
    },
    async runLoader() {
      this.openEditFormItemModal = true;
      // run the loader
      await this.$apollo.mutate({
        mutation: SetLoadingIndicatorInCache,
        variables: {
          isIndicatorLoading: true,
        },
      });
    },
    async editFormContent() {
      console.log('editing form...');
      this.openEditFormItemModal = false;
      await this.$apollo.queries.formDetailData.refetch();
      console.log('formDetailData', this.formDetailData);
    },
    deleteForm() {
      console.log('deleting form...');
    },
    async sendFormResponse() {
      console.log('sending form response...');
      const insertObject = {
        form_id: this.formItemId,
        response_data: {
          question1Name: 'question1Value',
          question2Name: 'question2Value',
          questionNName: 'questionNValue',
        },
      };
      console.log('insertObject', insertObject);
      await this.$apollo.mutate({
        mutation: InsertFormRes,
        variables: {
          object: insertObject,
        },
      });
      await this.$apollo.queries.formDetailData.refresh();
    },
    deleteFormResponse() {
      console.log('deleting form response...');
    },
    addItems(itemsToInsert, itemsToDelete) {
      console.log('adding item to form...');
      this.$apollo.mutate({
        mutation: SetLoadingIndicatorInCache,
        variables: {
          isIndicatorLoading: true,
        },
      });

      const addDeleteItemsPromises = [];

      if (itemsToInsert.length) {
        for (let i = 0; i < itemsToInsert.length; i++) {
          const { itemId } = itemsToInsert[i];
          const { type } = itemsToInsert[i];
          let insertObject = {
            form_id: this.formItemId,
            folder_id: null,
            project_id: null,
            page_id: null,
          };

          switch (type) {
            case 'group':
              insertObject.folder_id = itemsToInsert[i].rootFolderId;
              break;
            case 'folder':
              insertObject.folder_id = itemId;
              break;
            case 'project':
              insertObject.project_id = itemId;
              break;
            case 'page':
              insertObject.page_id = itemId;
              break;
            default:
              insertObject = null;
              break;
          }

          if (insertObject !== null) {
            addDeleteItemsPromises.push(this.$apollo.mutate({
              mutation: InsertFormItem,
              variables: {
                object: insertObject,
              },
            }));
          }
        }
      }

      if (itemsToDelete.length) {
        for (let i = 0; i < itemsToDelete.length; i++) {
          const item = itemsToDelete[i];
          const obj = {
            form_id: this.formItemId,
            folder_id: null,
            project_id: null,
            page_id: null,
          };

          switch (item.type) {
            case 'group':
              obj.folder_id = item.rootFolderId;
              break;
            case 'folder':
              obj.folder_id = item.itemId;
              break;
            case 'project':
              obj.project_id = item.itemId;
              break;
            case 'page':
              obj.page_id = item.itemId;
              break;
            default:
              break;
          }

          addDeleteItemsPromises.push(this.$apollo.mutate({
            mutation: DeleteFormItem,
            variables: { obj },
          }));
        }
      }

      if (addDeleteItemsPromises.length > 0) {
        Promise.allSettled(addDeleteItemsPromises).then((result) => {
          console.log('all promises settled', result);
          this.$apollo.queries.formDetailData.refresh();
          this.$apollo.mutate({
            mutation: SetLoadingIndicatorInCache,
            variables: {
              isIndicatorLoading: false,
            },
          });
        }).catch((e) => {
          console.log('error', e);
          this.$apollo.mutate({
            mutation: SetLoadingIndicatorInCache,
            variables: {
              isIndicatorLoading: false,
            },
          });
        });
      }
    },
    async removeItem(item) {
      console.log('removing item from form...');
      try {
        await this.$apollo.mutate({
          mutation: DeleteFormItem,
          variables: {
            obj: {
              form_id: this.formItemId,
              folder_id: item.folder_id,
              project_id: item.project_id,
              page_id: item.page_id,
            },
          },
        });
        this.$apollo.queries.formDetailData.refresh();
      } catch (ex) {
        console.error(ex);
      }
    },
    async removeTemplateImage() {
      await this.$apollo.mutate({
        mutation: UpdateForm,
        variables: {
          obj: {
            form_id: this.formItemId,
            preview_image_url: 'removeImage',
          },
        },
      });
      this.templateImageUrl = null;
    },
    async updateTemplateImage(imageObject) {
      if (imageObject?.url) {
        const { url } = imageObject;
        await this.$apollo.mutate({
          mutation: UpdateForm,
          variables: {
            obj: {
              form_id: this.formItemId,
              preview_image_url: url,
            },
          },
        });
        this.templateImageUrl = url;
      }
    },
    updateTemplateFlag(value) {
      this.$apollo.mutate({
        mutation: UpdateForm,
        variables: {
          obj: {
            form_id: this.formItemId,
            is_template: value,
          },
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.form-builder {
  background-color: #53575B;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);
  color: white;
}

.h3 .h4 {
  display: inline-block;
}

.cell-background-image {
  width: 100%;
  height: 100%;
  min-height: 36px;
  min-width: 50px;
  background-color: #777C7F;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.table-dark {
  background-color: transparent;
}

/deep/ .table-view .btn.dot-button {
  padding: 0;
  line-height: 1;

  text-align: center;
  vertical-align: text-top;
}

/deep/ table.table-view {
  font-size: 15px;
  border-collapse: collapse;
  border-spacing: 0 8px;
  color: $webmag-light-grey;

  thead th {
    text-transform: uppercase;
    font-size: 13px;
    padding: 7px 8px;
  }

  tbody tr:not([class="b-table-has-details"]) {
    border-bottom: 8px solid #44484c;
  }

  tbody tr {
    background-color: #53575B;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);

    > td {
      padding: 7px 8px;

      &:first-child {
        padding: 0;
        vertical-align: middle;
        text-align: center;
      }

      &:last-child {
        xdisplay: none;
        padding: 0;

        button {
          width: 40px;
          min-height: 36px;
        }
      }
    }
  }
}

.search-group {
  width: 150px;
  background-color: #5b6064;
  padding-left: 11px;
}

#content #filter-input, #content #filter-input:focus {
  color: #b9b9b9;
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  height: calc(1.5em + 0.75rem)
}

#filter-input::-webkit-search-cancel-button {
  appearance: none;
  height: 23px;
  width: 20px;
  background-size: 21px;
  background: url('../../../assets/svg/icon-search-cancel.svg') no-repeat center center;
  cursor: pointer;
}

button.btn.active svg {
  color: #5cb85c;
}

/deep/ .btn.dot-button {
  color: #b9b9b9;
  background: transparent;
  border: none;
  padding: 5px;

  &:hover {
    color: #fff;
  }
}

/deep/ .dropdown-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #e0e2e4;

  svg {
    padding-left: 5px;
    margin-left: 5px;
  }
}

.chevron-parent {
  flex-basis: 40px;

  .contacts-header-arrows {
    background-color: $webmag-grey;
    width: 50px;
    align-items: center;
    justify-content: center;
    height: 2.5em;

    svg {
      cursor: pointer;
    }
  }
}

.alert-message {
  border: 1px solid #A05B5B;
}
</style>
