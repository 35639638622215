import gql from 'graphql-tag';

export default gql`
  mutation insertFormRes($object: insertFormResponseInput!) {
    insertFormResponse(object: $object) {
      form_id
      response_data
    }
  }
`;
