import gql from 'graphql-tag';

export default gql`
query getFormDetails($formId: Int!) {
  getFormDetails(object: { formId: $formId }) {
    form_id
    name
    description
    is_template
    preview_image_url
    form_data
    forms_items
    form_responses
  }
}
`;
