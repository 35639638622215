import gql from 'graphql-tag';

export default gql`
  mutation insertFormItem($object: insertFormItemInput!) {
    insertFormItem(object: $object) {
      form_id
      folder_id
      project_id
      page_id
    }
  }
`;
