var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "mb-2" },
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex align-items-center",
              attrs: { cols: "12", "offset-sm": "1", sm: "11" }
            },
            [
              _c("h3", { staticClass: "mb-0" }, [
                _vm._v(_vm._s(_vm.formItem.name))
              ]),
              _c(
                "b-dropdown",
                {
                  staticClass: "context-menu",
                  attrs: {
                    "toggle-class": "dot-button",
                    "no-caret": "",
                    "no-flip": "",
                    right: ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "button-content",
                      fn: function() {
                        return [
                          _c("b-icon", {
                            attrs: {
                              icon: "three-dots-vertical",
                              "aria-hidden": "true",
                              scale: "1"
                            }
                          })
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c(
                    "b-dropdown-item",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal.modal-edit-form-item",
                          modifiers: { "modal-edit-form-item": true }
                        }
                      ]
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("dropdowns.edit")) + " ")]
                  ),
                  _c(
                    "b-dropdown-item",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal.modal-delete-form-item",
                          modifiers: { "modal-delete-form-item": true }
                        }
                      ]
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("dropdowns.delete")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "11" } }, [
            _c("hr")
          ]),
          _c("modal-edit-form-item", {
            staticClass: "d-inline-block",
            attrs: { formItem: _vm.formItem },
            on: { editFormItem: _vm.editFormItem }
          }),
          _c("modal-delete-form-item", {
            staticClass: "d-inline-block",
            attrs: { formItem: _vm.formItem },
            on: { deleteFormItem: _vm.deleteFormItem }
          })
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-5" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "11" } }, [
            _c("p", { staticClass: "mb-0" }, [
              _vm._v(_vm._s(_vm.formItem.description))
            ])
          ])
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-5" },
        [
          _c(
            "b-col",
            { attrs: { cols: "12", "offset-sm": "1", sm: "11" } },
            [
              _c(
                "b-button",
                {
                  staticClass:
                    "green-button-transparent border-0 mr-3 ml-3 mb-3",
                  on: { click: _vm.sendFormResponse }
                },
                [
                  _c("b-icon", {
                    staticClass: "mr-2 top--3",
                    attrs: {
                      icon: "plus-circle-fill",
                      "aria-hidden": "true",
                      scale: "1"
                    }
                  }),
                  _vm._v(" Send Form Response ")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-5" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "11" } }, [
            _c(
              "div",
              { staticClass: "form-builder p-4 row" },
              [
                _c("b-col", { attrs: { cols: "6" } }, [
                  _c("h4", [_vm._v("Form Builder")]),
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v("Form Builder Description")
                  ])
                ]),
                _c(
                  "b-col",
                  { staticClass: "align-content-end", attrs: { cols: "6" } },
                  [
                    _c(
                      "div",
                      { staticClass: "float-right d-flex" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass:
                              "green-button-transparent border-0 mr-3 ml-3 mb-3",
                            on: { click: _vm.runLoader }
                          },
                          [
                            _c("b-icon", {
                              staticClass: "mr-2 top--3",
                              attrs: {
                                icon: "plus-circle-fill",
                                "aria-hidden": "true",
                                scale: "1"
                              }
                            }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.formDetailData.form_data
                                    ? _vm.$t("buttons.editFormContent")
                                    : _vm.$t("buttons.createFormContent")
                                ) +
                                " "
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          ]),
          _vm.openEditFormItemModal
            ? _c("modal-edit-form-content", {
                staticClass: "d-inline-block",
                attrs: { formDetails: _vm.formDetailData },
                on: {
                  editFormContent: _vm.editFormContent,
                  cancel: function($event) {
                    _vm.openEditFormItemModal = false
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-2" },
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex align-items-center",
              attrs: { cols: "12", "offset-sm": "1", sm: "3" }
            },
            [
              _c("h3", { staticClass: "mb-0 text-uppercase" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("modals.formItemDescription.responses")) +
                    " "
                )
              ])
            ]
          ),
          _c(
            "b-col",
            {
              staticClass: "align-content-end",
              attrs: { cols: "12", sm: "8" }
            },
            [
              _c(
                "div",
                { staticClass: "float-right d-flex" },
                [
                  _c(
                    "b-input-group",
                    { staticClass: "search-group d-flex align-items-center" },
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "search",
                          "aria-hidden": "true",
                          scale: "1"
                        }
                      }),
                      _c("b-form-input", {
                        attrs: {
                          id: "filter-input",
                          type: "search",
                          placeholder: _vm.$t("webmag.search")
                        },
                        model: {
                          value: _vm.filter,
                          callback: function($$v) {
                            _vm.filter = $$v
                          },
                          expression: "filter"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-5" },
        [
          _c(
            "b-col",
            { attrs: { cols: "12", "offset-sm": "1", sm: "11" } },
            [
              _vm.responses
                ? _c("b-table", {
                    ref: "responsesTable",
                    staticClass: "table-view responses-table",
                    attrs: {
                      id: "responsesTable",
                      fields: _vm.responsesFields,
                      items: _vm.responses,
                      filter: _vm.filter,
                      "sort-icon-left": "",
                      "show-empty": "",
                      borderless: "",
                      dark: ""
                    },
                    on: {
                      "sort-changed": _vm.sortChanged,
                      "context-changed": _vm.sortChanged
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "table-colgroup",
                          fn: function(scope) {
                            return _vm._l(scope.fields, function(field) {
                              return _c("col", {
                                key: field.key,
                                style: { width: field.width }
                              })
                            })
                          }
                        },
                        {
                          key: "cell(order)",
                          fn: function(data) {
                            return [
                              _c("div", { on: { click: data.toggleDetails } }, [
                                _vm._v(" " + _vm._s(data.item.id) + " ")
                              ])
                            ]
                          }
                        },
                        {
                          key: "cell(name)",
                          fn: function(data) {
                            return [
                              _c("div", { on: { click: data.toggleDetails } }, [
                                _vm._v(" " + _vm._s(data.item.name) + " ")
                              ])
                            ]
                          }
                        },
                        {
                          key: "cell(created)",
                          fn: function(data) {
                            return [
                              _c("div", { on: { click: data.toggleDetails } }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      data.item.created_at.substring(0, 10)
                                    ) +
                                    " "
                                )
                              ])
                            ]
                          }
                        },
                        {
                          key: "cell(image)",
                          fn: function(data) {
                            return [
                              _c("div", { staticClass: "chevron-parent" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "contacts-header-arrows d-flex",
                                    on: { click: data.toggleDetails }
                                  },
                                  [
                                    !data.item._showDetails
                                      ? _c("b-icon", {
                                          staticClass:
                                            "top-4 position-relative",
                                          staticStyle: { color: "#fff" },
                                          attrs: {
                                            icon: "chevron-right",
                                            "aria-hidden": "true",
                                            scale: "1.2"
                                          }
                                        })
                                      : _c("b-icon", {
                                          staticClass:
                                            "top-4 position-relative",
                                          staticStyle: { color: "#fff" },
                                          attrs: {
                                            icon: "chevron-down",
                                            "aria-hidden": "true",
                                            scale: "1.2"
                                          }
                                        })
                                  ],
                                  1
                                )
                              ])
                            ]
                          }
                        },
                        {
                          key: "row-details",
                          fn: function(data) {
                            return [
                              _c(
                                "b-card",
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c("b-col", [
                                        _c(
                                          "div",
                                          {
                                            attrs: {
                                              id:
                                                "surveyElement-" + data.item.id
                                            }
                                          },
                                          [
                                            _c("form-results", {
                                              attrs: {
                                                formItemId: data.item.id,
                                                formResults:
                                                  data.item.response_data,
                                                formContent:
                                                  _vm.formDetailData.form_data
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      577372938
                    )
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-2" },
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex align-items-center",
              attrs: { cols: "12", "offset-sm": "1", sm: "3" }
            },
            [
              _c("h3", { staticClass: "mb-0 text-uppercase" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("modals.formItemDescription.items")) + " "
                )
              ])
            ]
          ),
          _c(
            "b-col",
            {
              staticClass: "align-content-end",
              attrs: { cols: "12", sm: "8" }
            },
            [
              _c(
                "div",
                { staticClass: "float-right" },
                [
                  _vm.userId
                    ? _c("modal-add-new-report-item", {
                        staticClass: "d-inline-block",
                        attrs: {
                          "user-id": _vm.userId,
                          "items-from-d-b": _vm.items
                        },
                        on: { addItems: _vm.addItems }
                      })
                    : _vm._e()
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-5" },
        [
          _c(
            "b-col",
            { attrs: { cols: "12", "offset-sm": "1", sm: "11" } },
            [
              _c("b-table", {
                staticClass: "table-view items-table",
                attrs: {
                  fields: _vm.itemFields,
                  items: _vm.items,
                  "show-empty": "",
                  borderless: "",
                  dark: ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "table-colgroup",
                    fn: function(scope) {
                      return _vm._l(scope.fields, function(field) {
                        return _c("col", {
                          key: field.key,
                          style: { width: field.width }
                        })
                      })
                    }
                  },
                  {
                    key: "cell(image)",
                    fn: function(data) {
                      return [
                        data.item.type === "folder"
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "cell-folder cell-background-image d-flex justify-content-center align-items-center"
                              },
                              [
                                _c("b-icon", {
                                  attrs: {
                                    icon: "folder",
                                    "aria-hidden": "true",
                                    scale: "1"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        data.item.type === "project"
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "cell-background-image d-flex justify-content-center align-items-center"
                              },
                              [
                                _c("b-icon", {
                                  attrs: {
                                    icon: "files",
                                    "aria-hidden": "true",
                                    scale: "1"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        data.item.type === "page"
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "cell-background-image d-flex justify-content-center align-items-center"
                              },
                              [
                                _c("b-icon", {
                                  attrs: {
                                    icon: "file",
                                    "aria-hidden": "true",
                                    scale: "1"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "cell(name)",
                    fn: function(data) {
                      return [
                        _c(
                          "span",
                          { attrs: { id: `tooltip-name-${data.item.id}` } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("sliceStingPos")(data.item.name, 23)
                                ) +
                                " "
                            )
                          ]
                        ),
                        data.item.name.length > 23
                          ? _c(
                              "b-tooltip",
                              {
                                attrs: {
                                  target: `tooltip-name-${data.item.id}`
                                }
                              },
                              [_vm._v(" " + _vm._s(data.item.name) + " ")]
                            )
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "cell(group)",
                    fn: function(data) {
                      return [
                        _c(
                          "span",
                          { attrs: { id: `tooltip-group-${data.item.id}` } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("sliceStingPos")(
                                    data.item.group_name,
                                    20
                                  )
                                ) +
                                " "
                            )
                          ]
                        ),
                        data.item.group_name.length > 20
                          ? _c(
                              "b-tooltip",
                              {
                                attrs: {
                                  target: `tooltip-group-${data.item.id}`
                                }
                              },
                              [_vm._v(" " + _vm._s(data.item.group_name) + " ")]
                            )
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "cell(dot)",
                    fn: function(data) {
                      return [
                        _c(
                          "b-dropdown",
                          {
                            staticClass: "context-menu",
                            attrs: {
                              "toggle-class": "dot-button",
                              "no-caret": "",
                              dropup: "",
                              "no-flip": "",
                              right: ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "button-content",
                                  fn: function() {
                                    return [
                                      _c("b-icon", {
                                        attrs: {
                                          icon: "three-dots-vertical",
                                          "aria-hidden": "true",
                                          scale: ".8"
                                        }
                                      })
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _c(
                              "b-dropdown-item",
                              {
                                directives: [
                                  {
                                    name: "b-modal",
                                    rawName: "v-b-modal",
                                    value: `remove-${data.item.type}-${data.item.id}-${_vm.formItemId}`,
                                    expression:
                                      "`remove-${data.item.type}-${data.item.id}-${formItemId}`"
                                  }
                                ]
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "modals.deleteItemFromForm.dropdown"
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c("modal-remove-item-from-form", {
                          staticClass: "d-inline-block",
                          attrs: {
                            item: data.item,
                            "form-index": _vm.formItemId
                          },
                          on: { "remove-item": _vm.removeItem }
                        })
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.isSysAdmin
        ? _c(
            "b-row",
            { staticClass: "mb-5" },
            [
              _c(
                "b-col",
                {
                  staticStyle: { border: "1px solid red" },
                  attrs: { cols: "12", "offset-sm": "1", sm: "11" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "pt-3 pb-4",
                      attrs: { id: "template-checkbox-section" }
                    },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          on: { change: _vm.updateTemplateFlag },
                          model: {
                            value: _vm.useAsTemplate,
                            callback: function($$v) {
                              _vm.useAsTemplate = $$v
                            },
                            expression: "useAsTemplate"
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("settings.forms.useAsTemplate")) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm.useAsTemplate
                    ? _c(
                        "div",
                        {
                          staticClass: "pb-4",
                          attrs: { id: "image-upload-section" }
                        },
                        [
                          _c("image-upload", {
                            attrs: { "image-object": _vm.templateImageObject },
                            on: {
                              "upload-image-object": _vm.updateTemplateImage,
                              "file-removed": _vm.removeTemplateImage
                            }
                          }),
                          _c("b-form-text", [
                            _vm._v(
                              _vm._s(_vm.$t("settings.forms.templateImgDesc"))
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }